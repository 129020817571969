import { ZoomWebinar, ZoomWebinarOccurrence, ZoomWebinarRecurrence } from 'types/zoom';

export default function makeMockZoomWebinar(
  title: string,
  startDate: Date | null,
  occurrences: ZoomWebinarOccurrence[] | null,
  recurrence: ZoomWebinarRecurrence | null
): ZoomWebinar {
  return {
    agenda: 'This is only a test.',
    duration: 60,
    id: 0,
    isRecurring: !!occurrences?.length && !!recurrence,
    joinUrl: 'https://wheniwork.com',
    occurrences,
    recurrence,
    startTime: startDate ? startDate.toString() : '',
    topic: title,
  };
}
