const mockACFWebinarPageTemplate = {
  event_hero: {
    zoom_webinar_id: 0,
    wistia_id: null,
    background_image: null,
    before_event: {
      signup: {
        button_text: null,
        thank_you_message: {
          header: null,
          copy: null,
        },
        header_above_signup_modal_activate_button: null,
        signup_modal_activate_button_text: null,
        signup_modal: {
          modal_title: null,
          modal_legal_text: null,
          modal_submit_button_text: null,
        },
        recurring_webinar_header: null,
        recurring_webinar_copy: null,
      },
    },
    during_event: {
      signup: {
        thank_you_message: {
          header: null,
          copy: null,
        },
        join_event_button_text: null,
        joining_late_message: null,
      },
    },
    after_event: {
      header_text: null,
      pre_registration_copy: null,
      register_button_text: null,
      recording_available_soon_message: null,
      watch_recording_button_text: null,
    },
  },
  cards_1: {
    card: [],
  },
  leader: {
    title: null,
    leader: [],
  },
  customer_reviews: {
    title: null,
    sub_title: null,
    customer: [],
  },
};

export default mockACFWebinarPageTemplate;
